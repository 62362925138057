@import "sass-lib";

@media screen and(min-width:768px) and(max-width:1366px){
	footer {
		position: relative; padding: 35px 0px 10px;
		.wrapper { width: 1125px; }
		.logo { position: absolute; display: inline-block; top: 0px;
			img { max-height: 39px; }
		}
		.address { display: none; }
		.menu-socmed {
			max-width: 100%; width: 100%; float: none; text-align: center; padding-top: 15px;
			.container-mobile {
				text-align: right;
				b { display: inline-block; position: relative; top: -9px; margin-right: 0px; }
				.socmed { width: auto; display: inline-block; margin : 6px 0px;
					div { margin-right: 0px; margin-left: 31px; }
				}
			}
			.menu { display: inline-block; margin: 20px auto; }
		}
	}
}

@media screen and(min-width:1024px) and(max-width:1440px){
	.hide-desktop { display: none; }
	body {
		div.rect-big {
			.map { margin-left: 430px; }
		}
		&.home {
			section {
				&.project {
					.bg-content { clip-path: url("#clipPolygonDesktop") }
				}
				&.news {
					padding-top: 154px;
				}
			}
		}
		&:not(.home) {
			section {
				&.content {
					div.wrapper-2 { width: 1125px; 
						div.content { margin-right: 0px; }
					}
				}
			}
		}
	}
	div.big-text-bg {
		img { max-height: 133px; }
	}
}

@media screen and(min-width:1281px) and(max-width:1680px){
	.wrapper { width: 1125px; }
	.wrapper-side { width: 95%; }
	body {
		&.home {
			header {
				.bottom {
					.logo {
						img { max-height: 46px; }
					}
					.menu {
						li { padding: 21px 13px; }
					}
					.wrapper { padding:19px 0px; }
				}
				&.active {
					.bottom {
						.menu {
							li {
								> a { padding: 36px 13px; }
							}
						}
					}
				}
			}
			section {
				&.project {
					.bg-content { clip-path: url("#clipPolygonDesktop") }
				}
			}
		}
	}
}

@media screen and(min-width:1023px) and(max-width:1300px){
	body.home section.mainbanner .list {background-size: cover !important; max-height: 620px;}
}